import {useCartService} from '@/composables/cart_service'
import {usePandaService} from '@/composables/panda_service'
import {useCartOptionsService} from '@/composables/cart_options_service'
import {useGtm} from '@gtm-support/vue-gtm'
import cloneDeep from 'lodash/cloneDeep'
import {
  ITEMS,
  ADDITEM_STATUS,
  ADDRESS_TYPE,
  USER_SETTING_VALUES,
  USER_RIGHTS_MAPPING
} from '~/assets/js/constants'
import {generateTempId} from '~/assets/js/utils'
import {getMappedProducts} from '~/assets/js/utils/product_attributes_mapping'
import {useUserStore} from './user'
import {useConfigurationDataStore} from './configuration-data'
import {defineStore} from 'pinia'
import {MAX_CART_ITEMS_FALLBACK_VALUE} from '~/assets/js/constants'

function setWarnings(item) {
  item.quantityWarnings = []
  item.warnings = []
  item?.itemWarnings?.forEach(w => {
    ITEMS.QUANTITY_WARNINGS.includes(w) ? item.quantityWarnings.push({...w, isWarning: true}) : item.warnings.push({...w, isWarning: true})
  })
}

const hasRight = (listOfRights, right) => listOfRights && listOfRights.some((item) => item === right)

const timeoutAwaiter = async function (timeout) {
  return new Promise((r) => setTimeout(r, timeout))
}

const emptyOrderSettings = {
  orderReference: '',
  endCustomerReference: '',
  orderNote: '',
  isBulk: null
}

export const useCartStore = defineStore('CartModule', {
  persist: {
    storage: persistedState.localStorage,
    paths: ['selectedCartItems', 'selectedAddresses', 'orderSettings']
  },
  state: () => ({
    metainformation: {
      'currency': 'EUR',
      'amountOfDecimals': 2
    },
    items: [],
    productItems: [],
    productItemsLocale: null,
    itemsLoading: true,
    certificates: [],
    selectedCartItems: [],
    selectedAddresses: {delivery: null, invoice: null},
    orderSettings: {...emptyOrderSettings},
    processedItems: [],
    pricesAreLoading: false,
    hasPrices: false,
    addedItemsSku: [],
    cartCount: 0,
    itemsNotLoaded: true,
    addressDropDownVisible: false,
    maxCartItems: MAX_CART_ITEMS_FALLBACK_VALUE
  }),
  getters: {
    availableCertificates() {
      return this.certificates
    },
    deliveryAddressUuid() {
      return this.selectedAddresses.delivery?.addressUuid ?? ''
    },
    deliveryAddress() {
      return this.selectedAddresses.delivery
    },
    itemsComputed() {
      return this.items.map(i => ({...i}))
    },
    cartCountComputed() {
      return this.itemsNotLoaded ? this.cartCount : this.items.length
    },
    validMaximalCartItems: (state) => (itemsToAdd) => {
      return (itemsToAdd.length + state.cartCountComputed) <= state.maxCartItems
    }
  },
  actions: {
    async fetchCartItems(region, locale, fetchPrices = false) {
      const {getAllItems} = useCartService()
      this.itemsLoading = true
      let items = null
      let products = null
      let cart = null
      const userStore = useUserStore()
      if (userStore.newCustomer !== null) {
        return
      }
      const rightsList = userStore.rights
      if (hasRight(rightsList, USER_RIGHTS_MAPPING.CART_VIEW)) {
        cart = await getAllItems(region, locale)
      }

      if (cart) {
        items = cart.items
        products = cart.products
      }

      if (items) {
        const filteredItems = items.filter(i => !this.processedItems.includes(i.cartItemUuid)).map(i => ({...i, isDirty: false}))
        this.setItemsAction([...filteredItems])
      }
      if (products) {
        products = getMappedProducts({products: products})
        this.setProductItems({items: products, locale})
      }

      if (fetchPrices && items) {
        this.setItemsLoading(false)
        this.fetchPrices()
      }
    },
    async fetchCartCount() {
      let cartCount = null
      let cartInfo = null
      const userStore = useUserStore()
      const {getCartCount, getCartInfo} = useCartService()
      const rightsList = userStore.rights
      if (hasRight(rightsList, USER_RIGHTS_MAPPING.CART_VIEW)) {
        cartCount = await getCartCount()
        cartInfo = await getCartInfo()
      }
      if (!isNaN(cartCount)) {
        this.setCartCount(cartCount)
      }
      if (cartInfo) {
        this.setCartInfo(cartInfo)
      }
    },
    async addItems({itemsToAdd, region, locale, item_list_id, item_list_name, useOwnArticleNo = false}) {
      this.setItemsLoading(true)
      const app = useNuxtApp()
      const userStore = useUserStore()
      const {addItems} = useCartService()
      const rightsList = userStore.rights
      // only allow adding if user has either buy or request permissions
      if (!hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_BUY) && !hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_REQUEST)) {
        return
      }
      if (!this.validMaximalCartItems(itemsToAdd)) {
        app.$toast.error(app.$i18n.t('cart.maxCartItemsError', {maxCartItems: this.maxCartItems}))
        this.setItemsLoading(false)
        return itemsToAdd.map(i => ({...i, errors: [{messageKey: 'Cart_Max_Items_Exceeded'}], status: ADDITEM_STATUS.ERROR}))
      }

      // generate unique Id
      let toAdd = itemsToAdd.map(i => ({...i, tempId: generateTempId()}))

      // add markedAsRequest if user only has right to request.
      if (!hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_BUY) && hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_REQUEST)) {
        toAdd = toAdd.map(i => ({...i, markedAsRequest: true}))
      }
      const res = await addItems(toAdd, region, locale, useOwnArticleNo)

      // check for custom response from service in case cart items are exceeding limit
      if (res?.errorCode === 'Cart_Max_Items_Exceeded') {
        app.$toast.error(app.$i18n.t('cart.maxCartItemsError', {maxCartItems: this.maxCartItems}))
        this.setItemsLoading(false)
        return itemsToAdd.map(i => ({...i, errors: [{messageKey: 'Cart_Max_Items_Exceeded'}], status: ADDITEM_STATUS.ERROR}))
      }

      const informations = res?.informations && res?.informations.length > 0 ? res.informations : null
      const items = res?.items && res?.items.length > 0 ? res.items : null
      let products = res?.products && res?.products.length > 0 ? res.products : null
      toAdd = toAdd.map(i => {
        return {...i, copFnstSku: useOwnArticleNo ? products?.find(p => p.customerMappings?.number === i?.sku)?.copFnstSku : products?.find(p => p?.sku?.toString() === i?.sku?.toString())?.copFnstSku,
          sku: useOwnArticleNo ? products?.find(p => p.customerMappings?.number === i?.sku)?.sku : products?.find(p => p?.sku?.toString() === i?.sku)?.sku,
          customerMappings: useOwnArticleNo ? products?.find(p => p.customerMappings?.number === i?.sku)?.customerMappings : products?.find(p => p?.sku?.toString() === i?.sku?.toString())?.customerMappings,
          addedQty: items?.find(p => p?.sku === i?.sku)?.quantity,
          unit: useOwnArticleNo ? products?.find(p => p.customerMappings?.number === i?.sku)?.variant?.unit : products?.find(p => p?.sku?.toString() === i?.sku?.toString())?.variant?.unit
        }
      })
      if (informations && Array.isArray(informations)) {
        const errorItems = informations.filter(r => r.itemErrors && r.itemErrors.length > 0)
        const noErrorItems = informations.filter(r => !errorItems.some(e => e.itemTempId === r.itemTempId))

        toAdd.filter(i => noErrorItems.some(r => i.tempId === r.itemTempId)).forEach(i => {
          i.status = ADDITEM_STATUS.SUCCESS
        })

        toAdd.filter(i => errorItems.some(r => i.tempId === r.itemTempId)).forEach(i => {
          i.status = ADDITEM_STATUS.ERROR
          i.errors = errorItems.find(r => i.tempId === r.itemTempId)?.itemErrors ?? []
        })

        this.setAddedItems(items?.map(i => i?.sku))
        this.setItems({res: {informations, items, isNew: true}, getPrices: true})

        // if cart is not yet loaded, add to cartCount
        if (this.itemsNotLoaded) {
          this.setCartCount(items && Array.isArray(items) ? this.cartCount + items.length : this.cartCount)
        }
      } else {
        toAdd.forEach(i => i.status = ADDITEM_STATUS.ERROR)
        this.setItemsLoading(false)
      }
      if (products) {
        products = getMappedProducts({products: products})
        const productItems = [...this.productItems, ...products]
        this.setProductItems({items: productItems, locale: locale || this.productItemsLocale})
      }

      let gtmItems = toAdd.filter(i => i.status === ADDITEM_STATUS.SUCCESS).map(item => {
        let product = products?.find(p => p?.sku === item?.sku)
        return {
          item_id: item?.sku,
          item_name: product?.locales[locale],
          currency: item.price?.currency,
          index: item.index,
          item_brand: product?.brandName,
          item_category: product?.productDesign,
          item_list_id: item_list_id,
          item_list_name: item_list_name,
          location_id: item.plantUuid,
          price: (item.price?.price && item.quantity) ? (item.price.price / item.quantity) : null,
          quantity: item.quantity
        }
      })
      let totalValue = gtmItems.filter(i => i.price).reduce((v, i) => v + i, 0)
      let currency = gtmItems.map(i => i.price?.currency).filter(c => c)[0]
      useGtm().trackEvent({
        event: 'add_to_cart',
        category: 'cart',
        action: 'add_to_cart',
        ecommerce: {currency, value: totalValue, items}
      })
      this.setItemsLoading(false)
      return toAdd
    },
    async updateItem({cartItem, oldCartItem, region, locale}) {
      const {updateItem} = useCartService()
      this.setIsDirty({cartItemUuid: cartItem.cartItemUuid, isDirty: true})
      let res = await updateItem(cartItem, region, locale)
      if (res === null) {
        const app = useNuxtApp()
        app.$toast.error(app.$i18n.t('cart.updateItemError'))
        cartItem = oldCartItem
        cartItem.hasQuantityChanged = true
        res = {items: [cartItem], informations: [{itemErrors: [], itemSku: cartItem?.sku}]}
      }
      else {
        let products = res.products && res.products.length > 0 ? res.products : null
        if (products) {
          products = getMappedProducts({products: products})
          const productItems = [...this.productItems, ...products]
          this.setProductItems({items: productItems, locale: locale || this.productItemsLocale})
        }
        this.updateItems(res?.items)
      }
      await this.setItems({res, getPrices: cartItem.hasQuantityChanged, cartItem})
    },
    async updateItemsDate({cartItems, oldCartItems, region, locale}) {
      const {updateItems} = useCartService()
      cartItems.forEach(cartItem => {
        this.setIsDirty({cartItemUuid: cartItem.cartItemUuid, isDirty: true})
      })
      let res = await updateItems(cartItems, region, locale)
      if (res === null) {
        const app = useNuxtApp()
        app.$toast.error(app.$i18n.t('cart.updateItemsDateError'))
        cartItems = oldCartItems
        res = {items: cartItems, informations: []}
      }
      else {
        let products = res.products && res.products.length > 0 ? res.products : null
        if (products) {
          products = getMappedProducts({products: products})
          const productItems = [...this.productItems, ...products]
          this.setProductItems({items: productItems, locale: locale || this.productItemsLocale})
        }
        let items = res.items && res.items.length > 0 ? res.items : null
        if (items) {
          items.forEach(item => {
            let relatedOldItem = oldCartItems.find(i => i.cartItemUuid === item.cartItemUuid)
            item.price = relatedOldItem.price
          })
        }
      }
      this.setItems({res, getPrices: false})
    },
    async deleteItems(cartItems, locale) {
      const {deleteItems} = useCartService()
      if (!cartItems || !Array.isArray(cartItems)) {
        return
      }
      let value = this.items?.map(i => i.price?.price).reduce((a, b) => a + b, 0)
      this.updateItems(cartItems.map(cartItem => {
        useGtm().trackEvent({
          event: 'remove_from_cart',
          category: 'cart',
          action: 'remove_item_from_cart',
          ecommerce: {
            currency: cartItem.price?.currency,
            value,
            items: [{
              item_id: cartItem?.sku,
              item_name: this.productItems?.find(p => p?.sku === cartItem?.sku)?.locales[locale],
              currency: cartItem.price?.currency,
              index: 0,
              item_brand: this.productItems?.find(p => p?.sku === cartItem?.sku)?.brandName,
              item_category: null,
              item_list_id: 'cart_selected_products',
              item_list_name: 'Selected Products',
              price: (cartItem.price?.price && cartItem.quantity) ? (cartItem.price.price / cartItem.quantity) : null,
              quantity: cartItem.quantity
            }]
          }
        })

        return {...cartItem, isDirty: true}
      }))
      this.deleteItemsAction(cartItems)
      this.updateSelectedItems([])
      const deleteSuccess = await deleteItems(cartItems.map(i => i.cartItemUuid))
      if (!deleteSuccess) {
        const app = useNuxtApp()
        app.$toast.error(app.$i18n.t('cart.errorDeletingCartItem'))
      }
    },
    async fetchCertificates() {
      let certificates = null
      const userStore = useUserStore()
      const {getCertificates} = useCartOptionsService()
      const rightsList = userStore.rights
      if (hasRight(rightsList, USER_RIGHTS_MAPPING.CART_VIEW)) {
        certificates = await getCertificates()
      }
      if (certificates) {
        this.setCertificates(certificates)
      }
    },
    setSelected(items) {
      this.updateSelectedItems(items)
    },
    preselectItem() {
      const userStore = useUserStore()
      const settingValues = userStore.settingValues
      return settingValues?.User?.UiMode === USER_SETTING_VALUES.UserModes.NormalMode
    },
    async itemsLoadedAwaiter() {
      let retries = 600
      while (retries > 0) {
        if (!this.itemsLoading) {
          return
        }
        await timeoutAwaiter(100)
        retries--
      }
      throw new Error('Could not load prices!')
    },
    async fetchPrices(ignoreCache = false) {
      await this.itemsLoadedAwaiter()
      this.setItemPrices({items: this.items, getPrices: true, track: true, ignoreCache: ignoreCache})
    },
    async setItems({res, getPrices, cartItem = null}) {
      const configurationDataStore = useConfigurationDataStore()
      const preselectItem = configurationDataStore.experimentalMode || this.preselectItem()
      const informations = res.informations
      let itemsAreNew = res?.isNew
      let items = res.items
      if (preselectItem && itemsAreNew) {
        this.updateSelectedItems(items?.map(i => ({cartItemUuid: i.cartItemUuid, selected: preselectItem})))
      }
      if (items !== null) {
        for (const item of items) {
          let information = informations?.find(i => i.itemSku === item?.sku)
          if (information && (!information.itemErrors || information.itemErrors.length <= 0) && information.itemWarnings) {
            item.itemWarnings = information.itemWarnings
            setWarnings(item)
          }
        }
        if (cartItem) {
          items = [{...cartItem, ...items[0]}]
        }
        this.setItemPrices({items, getPrices})
      }
    },
    setSelectedAddress({address, type}) {
      if (type === ADDRESS_TYPE.Delivery) {
        this.selectedAddresses.delivery = address
      }
      else if (type === ADDRESS_TYPE.Invoice) {
        this.selectedAddresses.invoice = address
      }
    },
    setOrderSettings(order) {
      this.orderSettings[order.type] = order.value
    },
    resetItemWarnings() {
      this.items.forEach(item => {
        item.quantityWarnings = []
      })
    },
    resetCart() {
      this.setItemsAction([])
    },
    resetOrderSettings() {
      this.orderSettings = {...emptyOrderSettings}
    },
    setProcessedItems(items) {
      const oldProcessedItems = this.processedItems || []
      this.processedItems = oldProcessedItems.concat(items)
    },
    async setItemPrices({items, getPrices, track = false, ignoreCache = false}) {
      const userStore = useUserStore()
      const {getMaterialPricesBulk} = usePandaService()
      const rightsList = userStore.rights
      let prices = []
      if (hasRight(rightsList, USER_RIGHTS_MAPPING.PRODUCT_VIEW_PRICE) && !this.pricesAreLoading && getPrices) {
        this.setPricesAreLoading()
        await getMaterialPricesBulk(items?.map(i => ({sku: i?.sku, quantity: i.quantity})), track, ignoreCache).then((bulkPrices) => {
          if (bulkPrices) {
            prices = bulkPrices
          }
        })
      }
      const itemsToUpdate = cloneDeep(items)
      itemsToUpdate.forEach(item => {
        let price = prices.find(p => p?.sku === item?.sku && p.quantity === item.quantity)
        item.price = price ? price : item.price ?? null
      })

      this.updateItems(itemsToUpdate)

      if (this.pricesAreLoading) {
        this.setPricesAreLoading()
      }
    },
    resetAddedItemsSku() {
      this.addedItemsSku = []
    },
    setIsDirtyFlag({cartItemUuid, isDirty}) {
      this.setIsDirty({cartItemUuid, isDirty})
    },
    setAddressDropdownVisibility(status) {
      this.addressDropDownVisible = !!status
    },
    setItemsAction(items) {
      this.items = items
      if (this.items.length === 0) {
        this.itemsLoading = false
      }
      if (this.itemsNotLoaded) {
        this.itemsNotLoaded = false
      }
    },
    setProductItems(payload) {
      this.productItems = payload.items
      this.productItemsLocale = payload.locale
    },
    setCartCount(count) {
      this.cartCount = count
      this.itemsNotLoaded = true
    },
    setCartInfo(cartInfo) {
      this.metainformation = cartInfo
    },
    setItemsLoading(loading) {
      this.itemsLoading = loading
    },
    setAddedItems(skus) {
      this.addedItemsSku = skus
    },
    setIsDirty(payload) {
      const {cartItemUuid, isDirty} = payload
      const index = this.items.findIndex(i => i.cartItemUuid === cartItemUuid)
      if (index !== -1) {
        this.items[index].isDirty = isDirty
      }
    },
    updateItems(items) {
      if (!items || !Array.isArray(items)) {
        return
      }

      items.forEach(item => {
        item.isDirty = false
        let index = this.items.findIndex(i => i.cartItemUuid === item.cartItemUuid || item.tempId && i.tempId && item.tempId === i.tempId)
        if (index !== -1) {
          this.items[index] = item
        } else {
          this.items.push(item)
        }
      })
    },
    deleteItemsAction(items) {
      this.items = this.items.filter(i => !items.some(ri => ri.cartItemUuid === i.cartItemUuid))
    },
    updateSelectedItems(items) {
      let selectedItems = [...this.selectedCartItems]
      // remove items which are not in current state items
      const oldStateItems = selectedItems.filter(si => !this.items.some(i => i.cartItemUuid === si))
      // newly deselected items
      const deselected = items.filter(i => !i.selected && selectedItems.indexOf(i.cartItemUuid) >= 0).map(i => i.cartItemUuid)
      const removeItems = deselected.concat(oldStateItems)
      for (let removeItemUuid of removeItems) {
        const index = selectedItems.indexOf(removeItemUuid)
        if (index >= 0) {
          selectedItems.splice(index, 1)
        }
      }

      // newly selected items
      const newItems = items.filter(si => si.selected && selectedItems.indexOf(si.cartItemUuid) < 0).map(i => i.cartItemUuid)
      for (let newUuid of newItems) {
        selectedItems.push(newUuid)
      }
      this.selectedCartItems = selectedItems
    },
    setCertificates(certificates) {
      this.certificates = certificates
    },
    setPricesAreLoading() {
      this.pricesAreLoading = !this.pricesAreLoading
      if (!this.hasPrices && !this.pricesAreLoading) {
        this.hasPrices = true
      }
    }
  }
})
